import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

import GoogleTagManager from '../GTM';
import RollbarAgent from '../RollbarAgent';
import CookieBot from '../CookieBot';
import KeepKeyboardFocusWithinCookiebotPopup from '../CookieBot/KeepKeyboardFocusWithinCookiebotPopup';
import htmlDecode from '../../../utils/htmlDecode';
import Convert, { ConvertStyle } from '../Convert';
import FundraiseUp from '../FundraiseUp';

const HtmlHead = ({
  nonce = '',
  metatags = [],
  pageTitle = 'Concern Worldwide',
  GTMQuery = '',
  GTMId = '',
  rollbarClientAccessToken = '',
  GTMDevMode = true,
  cookiebotId = '',
  convertEnabled = false,
  fundraiseUpEnabled = false,
  livePaymentInDevEnv = false,
  testSuite = false,
}) => {
  let title = pageTitle;
  const metatagsComponents = metatags.map((metatag, index) => {
    const Tag = metatag.tag;
    const decodedContent = {};

    // Grab the page title from the metatags.
    if (metatag.attributes && metatag.attributes.name && metatag.attributes.content) {
      if (Tag === 'meta' && metatag.attributes.name === 'title') {
        title = metatag.attributes.content;
      }
    }

    // Decode Unicode character representation to avoid social media referrer preview parsing
    // issues for URLs but ONLY if it's a meta tag, as some tags like <link> will interpret React
    // props as string literals, even if they are null or undefined
    if (Tag === 'meta' && metatag.attributes.content) {
      decodedContent.content = htmlDecode(String(metatag.attributes.content));
    }

    return <Tag key={`metatag-${index}`} {...metatag.attributes} {...decodedContent} />;
  });

  // Use development GTM by default.
  let previewVariables = GTMQuery;
  if (!GTMDevMode) {
    // Enable production GTM.
    previewVariables = '';
  }

  return (
    <>
      {convertEnabled && !testSuite && <Convert nonce={nonce} />}
      {/**
       * Cookiebot script must be loaded here in the code, and not using GTM.
       * Otherwise, in some cases it may happen side effect when cookiebot popup
       * may be displayed only in 5-10 seconds after displaying the content.
       */}
      {cookiebotId && <CookieBot cookiebotId={cookiebotId} nonce={nonce} />}
      {cookiebotId && <KeepKeyboardFocusWithinCookiebotPopup />}

      {GTMId && (
        <GoogleTagManager nonce={nonce} gtmId={GTMId} previewVariables={previewVariables} />
      )}

      {rollbarClientAccessToken && <RollbarAgent nonce={nonce} />}

      {fundraiseUpEnabled && !testSuite && (
        <FundraiseUp livePaymentInDevEnv={livePaymentInDevEnv} nonce={nonce} />
      )}

      <Head>
        {title && <title>{title}</title>}
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        {metatagsComponents}
      </Head>
      {/* We have to hide non "A" variants even if A/B testing is not enabled */}
      <ConvertStyle />
    </>
  );
};

HtmlHead.propTypes = {
  nonce: PropTypes.string,
  metatags: PropTypes.arrayOf(
    PropTypes.shape({
      tag: PropTypes.string.isRequired,
      attributes: PropTypes.shape({
        name: PropTypes.string,
        property: PropTypes.string,
        content: PropTypes.string,
      }).isRequired,
    }),
  ),
  pageTitle: PropTypes.string,
  GTMDevMode: PropTypes.bool,
  cookiebotId: PropTypes.string,
  GTMQuery: PropTypes.string,
  GTMId: PropTypes.string,
  rollbarClientAccessToken: PropTypes.string,
  convertEnabled: PropTypes.bool,
  fundraiseUpEnabled: PropTypes.bool,
  livePaymentInDevEnv: PropTypes.bool,
  testSuite: PropTypes.bool,
};

export default HtmlHead;
