import React from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import { DEFAULT_COUNTRY_CODE } from '../../../utils/constants';

const GoogleTagManager = ({
  nonce = '',
  gtmId,
  dataLayerName = 'dataLayer',
  additionalEvents = {},
  previewVariables = false,
}) => {
  const convertToKeyValueString = (obj) => {
    return JSON.stringify(obj).slice(1, -1);
  };
  const domain = DEFAULT_COUNTRY_CODE === 'IE' ? '//data.concern.net' : '//data.concern.org.uk';
  const gtmScript = `
        (function(w,d,s,l,i){w[l]=w[l]||[];
            w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js', ${convertToKeyValueString(additionalEvents)}});
            var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
            j.async=true;j.src='${domain}/gtm.js?id='+i+dl
            ${previewVariables ? `+"${previewVariables}"` : ''};var n=d.querySelector('[nonce]');
          n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));f.parentNode.insertBefore(j,f);
        })(window,document,'script','${dataLayerName}','${gtmId}');`;

  // Insert originalLocation into dataLayer before GTM script.
  // See https://www.simoahava.com/gtm-tips/fix-rogue-referral-problem-single-page-sites/
  // Note that this code is always executed on server side.
  const originalLocationScript = `window.${dataLayerName} = window.${dataLayerName} || [];window.${dataLayerName}.push({originalLocation: document.location.protocol + "//" + document.location.hostname + document.location.pathname + document.location.search});`;

  if (typeof window !== 'undefined') {
    const offLineHandler = () => {
      // https://developers.google.com/tag-platform/security/guides/privacy#gtag.js_5
      window['ga-disable-' + gtmId] = true;
    };

    const onLineHandler = () => {
      delete window['ga-disable-' + gtmId];
    };

    window.addEventListener('online', onLineHandler);
    window.addEventListener('offline', offLineHandler);
  }

  return (
    <Head>
      {/* Currently, we can't use Script component from next js because to not break order of scripts in Head */}
      <script dangerouslySetInnerHTML={{ __html: originalLocationScript }} nonce={nonce} />
      <script dangerouslySetInnerHTML={{ __html: gtmScript }} nonce={nonce} />
    </Head>
  );
};

GoogleTagManager.propTypes = {
  nonce: PropTypes.string.isRequired,
  gtmId: PropTypes.string.isRequired,
  dataLayerName: PropTypes.string,
  additionalEvents: PropTypes.shape(),
  previewVariables: PropTypes.string,
};

export default GoogleTagManager;
