import React from 'react';
import Head from 'next/head';
import Script from 'next/script';
import { ENVIRONMENT, DEFAULT_COUNTRY_CODE } from '../../../utils/constants';
import PropTypes from 'prop-types';

/**
 * We have multiple Convert projects configured here
 * https://app.convert.com/accounts/1004961
 * Determining corresponding Convert script for each project.
 */
const convertProjectId =
  ENVIRONMENT !== 'production'
    ? '10043192'
    : DEFAULT_COUNTRY_CODE === 'IE'
      ? '10041496'
      : '10043106';

/**
 * Asks Convert to re-apply variation scripts,
 * e.g. after client-side navigation.
 * See https://support.convert.com/hc/en-us/articles/205159975
 */
export const convertRun = () => {
  // eslint-disable-next-line no-console
  console.debug('Triggering Convert from Next.js');
  window._conv_q = window._conv_q || [];
  window._conv_q.push(['run', 'true']);
};

export const ConvertStyle = () => (
  <Head>
    <style
      dangerouslySetInnerHTML={{
        __html: `
.behaviour-settings__ab-variant--variant-b,
.behaviour-settings__ab-variant--variant-c {
  display: none;
}
.ab-variant--variant-b .behaviour-settings__ab-variant--variant-b {
  display: revert;
}
.ab-variant--variant-b .behaviour-settings__ab-variant--variant-a,
.ab-variant--variant-b .behaviour-settings__ab-variant--variant-c {
  display: none;
}
.ab-variant--variant-c .behaviour-settings__ab-variant--variant-c {
  display: revert;
}
.ab-variant--variant-c .behaviour-settings__ab-variant--variant-a,
.ab-variant--variant-c .behaviour-settings__ab-variant--variant-b {
  display: none;
}
`,
      }}
    />
  </Head>
);

/**
 * Injects Convert script and some setup code before it.
 *
 * Note that we are NOT using synchronous script as it's recommended by Convert.
 * In initial tests synchronous script significantly damaged performance and
 * didn't play well with Next.js.
 */
const Convert = ({ nonce }) => (
  <>
    <Script id="convert-setup" strategy="beforeInteractive" nonce={nonce}>
      {`
// Example from https://support.convert.com/hc/en-us/articles/360037946851-Delay-cookie-writing-until-visitor-consent-is-provided.
if (document.cookie.indexOf('_conv_') === -1) {
  if (typeof disable_cookies === 'undefined') {
    disable_cookies = true;
  }

  // Store the set and get original functions
  var cookie_setter_orig = document.__lookupSetter__('cookie').bind(document);
  var cookie_getter_orig = document.__lookupGetter__('cookie').bind(document);

  // Redefine the function to only enable cookies when the switch disable_cookies is true.
  Object.defineProperty(document, 'cookie', {
    get: function () {
      return cookie_getter_orig();
    },
    set: function (val) {
      // When disable cookies is false then the writing is enabled or when its not a Convert cookie
      if (!disable_cookies || !val.includes('_conv')) {
        cookie_setter_orig(val);
      } else {
        // console.log('Convert Cookie Blocked');
      }
    },
    configurable: true,
  });

  // Following example code is to disable the cookie blocking.
  // Adapted for Cookiebot.
  window.addEventListener(
    'CookiebotOnAccept',
    function (e) {
      if (Cookiebot && Cookiebot.consent.marketing) {
        disable_cookies = false;
        // Reload Convert Load Sequence (polling)
        // So the convert cookies can be written
        window._conv_q = _conv_q || [];
        _conv_q.push(['run', 'true']);
      }
    },
    false
  );
}

// This function will be called from Convert to inform
// our app about specific activated variant. It can be replaced with Events for better flexibility.
function cwConvertSetVariant(variant = 'A') {
  if (document.body) {
    document.body.classList.add('ab-variant--variant-' + variant.toLowerCase());
  }
}
        `}
    </Script>
    <Script
      src={`//cdn-4.convertexperiments.com/js/1004961-${convertProjectId}.js`}
      strategy="beforeInteractive"
      nonce={nonce}
    />
  </>
);

Convert.propTypes = {
  nonce: PropTypes.string.isRequired,
};

export default Convert;
